const auth = "auth";
const users = "users";
const wallets = "wallets";
const sessions = "sessions";
const bankAccounts = "bank-accounts";
const deposits = "deposits";
const withdraws = "withdraws";
const bankTransfer = "bank-transfer";
const crypto = "crypto";
const cryptoWithdrawAccounts = "crypto-withdrawal-accounts";
const whitelist = "whitelist";
const currencies = "currencies";
const ldContract = "ld-contracts";
const transactions = "transactions";
const proposals = "ld-proposals";

const url = {
  login: `${auth}/initiate-login`,
  verificationLogin: `${auth}/login`,
  logout: `${auth}/logout`,
  profile: "me",
  users: users,
  setRole: `${users}/set-role`,
  upgradeToLD: `${users}/upgrade-to-ld`,
  upgradeToLvl1: `${users}/upgrade-to-level-1`,
  wallets: wallets,
  sessions: sessions,
  bankAccounts: bankAccounts,
  transactions: transactions,
  proposals: proposals,
  roles: "roles",
  permissions: "permissions",
  countries: "countries",
  contacts: "contacts",
  currencies: currencies,
  blockUser: (id: string) => `${users}/${id}/suspension`,
  balance: (id: string) => `${users}/${id}/balance`,
  depositApprove: (id: string) => `${deposits}/${bankTransfer}/${id}/approve`,
  depositReject: (id: string) => `${deposits}/${bankTransfer}/${id}/reject`,
  withdrawApprove: (id: string) => `${withdraws}/${bankTransfer}/${id}/settle`,
  withdrawCryptoApprove: (id: string) => `${withdraws}/${crypto}/${id}/approve`,
  whitelist: `${whitelist}/${cryptoWithdrawAccounts}`,
  whitelistApprove: (id: string) =>
    `${whitelist}/${cryptoWithdrawAccounts}/${id}/approve`,
  updateLDContract: (id: string) => `${ldContract}/${id}`,
};
export default url;
