import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Columns, Session } from 'types';

const columns: Columns<Session> = () => [
  {
    title: 'IP',
    dataIndex: 'ip',
    hideInSearch: true,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (_: any, { created_at }: { created_at: string }) => {
      return dayjs(created_at).format('YYYY-MM-DD HH:mm:ss');
    },
    hideInSearch: true,
  },
  {
    title: 'User agent',
    dataIndex: 'user_agent',
    render: (_: any, { user_agent }: { user_agent: string }) => {
      return (
        <Tooltip title={user_agent} trigger="click">
          <InfoCircleOutlined style={{ paddingRight: 5 }} />
          {user_agent.slice(0, 30) + ' ...'}
        </Tooltip>
      );
    },
    hideInSearch: true,
  },
];

export default columns;
