import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { url } from 'statics';
import { Currency, ErrorResponse, ListResponse } from 'types';

const useCurrencies = (): UseQueryResult<
  ListResponse<Currency>,
  ErrorResponse
> => {
  return useQuery([url.currencies]);
};

export default useCurrencies;
