import { Row, Typography } from 'antd';
import magixLogo from 'assets/images/magix-logo.svg';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { usePublic } from 'hooks';

const UnAuthenticated: React.FC = () => {
  usePublic();

  const { Title } = Typography;

  return (
    <div style={{ width: '100%' }}>
      <Row
        justify="start"
        align="middle"
        style={{ padding: '8px 16px', boxShadow: '0 0 5px #ccc' }}
      >
        <img
          src={magixLogo}
          alt="magixlogo"
          style={{ margin: '0 10px', width: '30px' }}
        />
        <Title level={5} style={{ margin: 0 }}>
          Magic Exchange
        </Title>
      </Row>
      <Outlet />
    </div>
  );
};
export default UnAuthenticated;
