import { ProForm, ProFormSelect } from '@ant-design/pro-components';
import { Button, Divider, Flex, Form, Modal, Typography } from 'antd';
import { useRoles, useSetRole } from 'hooks';
import React from 'react';
import { generateFormProps } from 'utils';

type SetRoleModalProps = {
  username: string;
  roleId: string;
  userId: string;
  refresh: () => void;
};

const SetRoleModal: React.FC<SetRoleModalProps> = ({
  username,
  roleId,
  userId,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const { data: roles } = useRoles();

  const {
    mutateAsync: setRole,
    isLoading: setRoleLoading,
    error,
  } = useSetRole();

  const onFinish = async (data: { role: string }) => {
    setRole({ user: userId, ...data }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  const roleOptions = roles
    ? roles.list.map((role) => ({
        value: role.id,
        label: role.title,
        disabled: ['LEVEL0', 'LEVEL1', 'LD'].includes(role.title)
          ? true
          : false,
      }))
    : [];

  const rules = {
    role: [{ required: true }],
  };

  const formProps = generateFormProps(
    rules,
    { error },
    {
      role: 'Role',
    }
  );

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: 'unset', padding: '0 4px' }}
        loading={setRoleLoading}
      >
        Change role
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Set role to user"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        <Divider type="horizontal" />
        <ProForm<any>
          form={form}
          onFinish={async (values) => onFinish(values)}
          submitter={false}
          dateFormatter={false}
          initialValues={{ role: roleId }}
        >
          <Flex dir="row" justify="space-between" align="center">
            <Typography>Username : </Typography>
            <Typography style={{ fontWeight: 'bold' }}>{username}</Typography>
          </Flex>
          <br />
          <ProFormSelect {...formProps.role} options={roleOptions} />
        </ProForm>
      </Modal>
    </>
  );
};
export default SetRoleModal;
