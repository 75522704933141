import {
  MenuOutlined,
  PropertySafetyOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Row } from "antd";
import { routes } from "statics";
import magixLogo from "assets/images/magix-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

type SliderProps = {
  title: string;
  collapsed: boolean;
  isForMobile?: boolean;
  setOpenSidebar?: () => void;
};

const Slider: React.FC<SliderProps> = ({
  title,
  collapsed,
  isForMobile = false,
  setOpenSidebar,
}) => {
  const { Sider: AntdSlider } = Layout;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AntdSlider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ height: "100vh", overflow: "auto" }}
      className={isForMobile ? "" : "sidebar"}
    >
      {isForMobile && (
        <Button
          type="text"
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            padding: 0,
            stroke: "white",
            fill: "white",
            color: "white",
            margin: 16,
          }}
          onClick={setOpenSidebar}
        >
          <MenuOutlined />
        </Button>
      )}
      <Row
        justify="center"
        style={{
          padding: "8px",
          margin: "25px 0",
        }}
      >
        <img
          src={magixLogo}
          alt="magixlogo"
          style={{ margin: "0 10px", width: "25px" }}
        />
      </Row>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[title]}
        items={[
          {
            key: "dashboard/clients",
            label: "Clients",
            onClick: () => navigate(routes.clients.url),
            icon: <UserOutlined />,
            className:
              location.pathname === "/dashboard/clients"
                ? "ant-menu-item-selected"
                : "",
          },
          {
            key: "dashboard/transactions",
            label: "Transactions",
            onClick: () => navigate(routes.transactions.url),
            icon: <TransactionOutlined />,
            className:
              location.pathname === "/dashboard/transactions"
                ? "ant-menu-item-selected"
                : "",
          },
          {
            key: "dashboard/proposals",
            label: "Proposals",
            onClick: () => navigate(routes.proposals.url),
            icon: <PropertySafetyOutlined />,
            className:
              location.pathname === "/dashboard/proposals"
                ? "ant-menu-item-selected"
                : "",
          },
        ]}
      />
    </AntdSlider>
  );
};
export default Slider;
