import { Tag } from 'antd';
import { Columns, Currency, Wallet } from 'types';

const columns: Columns<Wallet> = (actions) => [
  {
    title: 'Title',
    dataIndex: ['currency', 'title'],
    hideInSearch: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    valueEnum: {
      FIAT: { text: <Tag color="green">Fiat</Tag> },
      CRYPTO: { text: <Tag color="orange">Crypto</Tag> },
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (
      _: string,
      { amount, currency }: { amount: number; currency: Currency }
    ) => {
      return (
        <p>
          {amount > 0 ? amount.toFixed(2) : amount}
          <span style={{ fontSize: '10px', paddingLeft: 2, color: 'green' }}>
            {currency.symbol}
          </span>
        </p>
      );
    },
    hideInSearch: true,
  },
  {
    title: 'Frozen amount',
    dataIndex: 'frozen_amount',
    render: (
      _: string,
      { frozen_amount, currency }: { frozen_amount: number; currency: Currency }
    ) => {
      return (
        <p>
          {frozen_amount > 0 ? frozen_amount.toFixed(2) : frozen_amount}
          <span style={{ fontSize: '10px', paddingLeft: 2, color: 'green' }}>
            {currency.symbol}
          </span>
        </p>
      );
    },
    hideInSearch: true,
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    valueEnum: {
      BITGO: { text: <Tag color="geekblue">BITGO</Tag> },
      INTERNAL: { text: <Tag color="default">INTERNAL</Tag> },
      EXTERNAL: { text: <Tag color="purple">EXTERNAL</Tag> },
    },
    hideInSearch: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    valueEnum: {
      ACTIVE: { text: 'Active', status: 'Success' },
      INACTIVE: { text: 'Inactive', status: 'Error' },
    },
  },
  {
    title: 'Actions',
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
