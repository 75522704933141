import { useQuery } from '@tanstack/react-query';
import { ErrorResponse, Me } from 'types';

import { url } from 'statics';

const useProfile = ({ enabled }: { enabled: boolean }) =>
  useQuery<Me, ErrorResponse, Me>([url.profile], {
    enabled,
  });

export default useProfile;
