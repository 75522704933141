import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse } from 'types';

const useWalletApprove = (): UseMutationResult<
  {},
  ErrorResponse,
  { address_id: string }
> =>
  useMutation(({ address_id }) =>
    api.put(url.whitelistApprove(address_id), {})
  );

export default useWalletApprove;
