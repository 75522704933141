import { Card, Descriptions, Typography } from 'antd';
import { Transaction } from 'types';

type SourceBankAccountInfoProps = {
  transaction?: Transaction;
};

const SourceBankAccountInfo: React.FC<SourceBankAccountInfoProps> = ({
  transaction,
}) => {
  const sourceBankTransferStats = [
    transaction?.source_bank_account?.bank_name && {
      key: '3',
      label: 'Bank name',
      children: (
        <Typography>{transaction?.source_bank_account?.bank_name}</Typography>
      ),
    },
    transaction?.source_bank_account?.bank_branch_name && {
      key: '4',
      label: 'Bank branch name',
      children: (
        <Typography>
          {transaction?.source_bank_account?.bank_branch_name}
        </Typography>
      ),
    },
    transaction?.source_bank_account?.account_id && {
      key: '5',
      label: 'Account ID',
      children: (
        <Typography>{transaction?.source_bank_account?.account_id}</Typography>
      ),
    },
    transaction?.source_bank_account?.phone && {
      key: '6',
      label: 'Phone',
      children: (
        <Typography>{transaction?.source_bank_account?.phone}</Typography>
      ),
    },
    transaction?.source_bank_account?.holder_name && {
      key: '7',
      label: 'Holder name',
      children: (
        <Typography>{transaction?.source_bank_account?.holder_name}</Typography>
      ),
    },
    transaction?.source_bank_account?.address && {
      key: '8',
      label: 'Address',
      children: (
        <Typography>{transaction?.source_bank_account?.address}</Typography>
      ),
    },
    transaction?.source_bank_account?.city && {
      key: '9',
      label: 'City',
      children: (
        <Typography>{transaction?.source_bank_account?.city}</Typography>
      ),
    },
    transaction?.source_bank_account?.country && {
      key: '10',
      label: 'Country',
      children: (
        <Typography>{transaction?.source_bank_account?.country}</Typography>
      ),
    },
    transaction?.source_bank_account?.SWIFT && {
      key: '11',
      label: 'SWIFT / BIC code',
      children: (
        <Typography>{transaction?.source_bank_account?.SWIFT}</Typography>
      ),
    },
  ].filter(Boolean) as any[];

  return (
    <Card>
      <Descriptions
        bordered
        layout="vertical"
        title="Source bank account information"
        items={[...sourceBankTransferStats]}
      />
    </Card>
  );
};

export default SourceBankAccountInfo;
