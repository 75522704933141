import { Card, Descriptions, Flex, Spin } from 'antd';
import { BlockUserModal } from '..';

type SensitiveInfoProps = {
  userId: string;
  isLoading: boolean;
  is_suspended: boolean | null;
  refetch: () => void;
};

const SensitiveInfo: React.FC<SensitiveInfoProps> = ({
  userId,
  isLoading,
  refetch,
  is_suspended,
}) => {
  const roleData = [
    {
      key: '1',
      label: 'Action',
      children: (
        <Flex>
          <BlockUserModal
            is_suspended={Boolean(is_suspended)}
            userId={userId || ''}
            refresh={refetch}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Card style={{ borderColor: 'red', backgroundColor: 'rgba(255,0,0,0.1)' }}>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions
          bordered
          layout="vertical"
          title="Sensitive actions"
          items={roleData}
        />
      )}
    </Card>
  );
};
export default SensitiveInfo;
