import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Typography, Form } from 'antd';
import OtpInput from 'react-otp-input';
import { useVerificationLogin } from 'hooks';
import { routes } from 'statics';
import useLocalStorageState from 'use-local-storage-state';

const Verification: React.FC<{
  token: string | null;
  username: string | null;
}> = ({ token, username }) => {
  const navigate = useNavigate();
  const [, setToken] = useLocalStorageState<string>('magix-admin-token');

  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const [otp, setOtp] = useState('');

  const { mutateAsync, isLoading } = useVerificationLogin({
    token: token || '',
  });

  const onFinish = (values: { code: string }) => {
    const payload = {
      ...values,
      username: username as string,
    };

    mutateAsync(payload).then((data) => {
      setToken(data.access_token);
      navigate(routes.clients.url);
    });
  };

  React.useEffect(() => {
    if (!token && !username) {
      navigate(routes.login.url);
    }
  }, [navigate, token, username]);

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        label="Code"
        name="code"
        rules={[
          {
            required: true,
            message: 'Please input your code!',
          },
        ]}
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          containerStyle={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          inputStyle="otp"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          SIGN IN
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Verification;
