import { useQuery } from '@tanstack/react-query';
import { Country, ErrorResponse, ListResponse } from 'types';

import { url } from 'statics';

const useCountries = ({ enabled = true }: { enabled?: boolean }) =>
  useQuery<ListResponse<Country>, ErrorResponse>([url.countries], {
    enabled,
  });

export default useCountries;
