import { ErrorResponse, ListResponse, Transaction } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyTransactions = ({ params }: { params: any }) => {
  return api.get<ListResponse<Transaction>, ErrorResponse, any>(
    url.transactions,
    {
      params,
    }
  );
};

export { queyTransactions };
