import { createBrowserRouter, Navigate } from "react-router-dom";
import { routes } from "statics";

import UnAuthenticated from "./UnAuthenticated";
import Authenticated from "./Authenticated";

import {
  Login,
  User,
  UserSessions,
  UserTransactions,
  UserWallets,
  UserBankAccount,
  Users,
  Page404,
  WalletWhitelist,
  Transactions,
  Transaction,
  Proposals,
  Proposal,
} from "pages";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/",
    element: <Navigate to={routes.dashboard.url} replace={true} />,
  },
  {
    path: `${routes.auth.url}`,
    element: <UnAuthenticated />,
    children: [
      {
        path: routes.login.url,
        element: <Login />,
      },
    ],
  },
  {
    path: `${routes.dashboard.url}`,
    element: <Authenticated />,
    children: [
      {
        path: routes.clients.url,
        children: [
          {
            path: routes.clients.url,
            element: <Users />,
          },
          {
            path: routes.client.url,
            children: [
              {
                path: routes.client.url,
                element: <User />,
              },
              {
                path: routes.clientWallets.url,
                children: [
                  {
                    path: routes.clientWallets.url,
                    element: <UserWallets />,
                  },
                  {
                    path: routes.walletWhitelist.url,
                    element: <WalletWhitelist />,
                  },
                ],
              },
              {
                path: routes.clientTransactions.url,
                element: <UserTransactions />,
              },
              {
                path: routes.clientBankAccounts.url,
                element: <UserBankAccount />,
              },
              {
                path: routes.clientSession.url,
                element: <UserSessions />,
              },
            ],
          },
        ],
      },
      {
        path: routes.transactions.url,
        children: [
          {
            path: routes.transactions.url,
            element: <Transactions />,
          },
          {
            path: routes.transaction.url,
            element: <Transaction />,
          },
        ],
      },
      {
        path: routes.proposals.url,
        children: [
          {
            path: routes.proposals.url,
            element: <Proposals />,
          },
          {
            path: routes.proposal.url,
            element: <Proposal />,
          },
        ],
      },
    ],
  },
]);
export default router;
