import { ErrorResponse, ListResponse, Transaction } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyUserTransactions = ({
  params,
  userId,
}: {
  params: any;
  userId: string;
}) => {
  return api.get<ListResponse<Transaction>, ErrorResponse, any>(
    url.transactions,
    {
      params: {
        ...params,
        user: userId,
      },
    }
  );
};

export { queyUserTransactions };
