import { Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { usePrivate } from 'hooks';

import { Col, Flex, Layout, Row, Typography } from 'antd';
import { Header, Sider } from 'components';

const UnAuthenticated: React.FC = () => {
  usePrivate();

  const { Content } = Layout;

  const [collapsed, setCollapsed] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const location = useLocation();

  const page = location.pathname.replace('/', '');
  const pageTitle = page.split('/')[1];

  return (
    <Layout>
      <Sider title={page} collapsed={collapsed} />
      <div
        className={`sidebar-mobile-close ${
          openSidebar ? 'sidebar-mobile-open' : ''
        }`}
      >
        <Sider
          title={page}
          collapsed={collapsed}
          isForMobile
          setOpenSidebar={() => setOpenSidebar(false)}
        />
      </div>
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        <Header
          title={pageTitle || page}
          collapsed={collapsed}
          setCollapsed={() => setCollapsed((prev) => !prev)}
          setOpenSidebar={() => setOpenSidebar(true)}
        />
        <Content style={{ margin: '16px' }}>
          {page === 'dashboard' && !pageTitle ? (
            <Flex align="center" justify="center">
              <Col>
                <Row>
                  <Typography.Title level={3}>
                    Welcome to exchangemagix admin
                  </Typography.Title>
                </Row>
              </Col>
            </Flex>
          ) : (
            <Outlet />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default UnAuthenticated;
