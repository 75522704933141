import {
  ProForm,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import { Button, Divider, Flex, Form, Modal, Typography } from 'antd';
import { useCountries, useUpgradeToLvl1 } from 'hooks';
import React from 'react';
import { generateFormProps } from 'utils';

type UpgradeToLvl1ModalProps = {
  username: string;
  userId: string;
  refresh: () => void;
};

const UpgradeToLvl1Modal: React.FC<UpgradeToLvl1ModalProps> = ({
  username,
  userId,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const { data: countries } = useCountries({});

  const {
    mutateAsync: upgradeToLvl1,
    error,
    isLoading: upgradeLoading,
  } = useUpgradeToLvl1();

  const onFinish = (data: {
    country: string;
    first_name: string;
    last_name: string;
  }) => {
    upgradeToLvl1({ user: userId, ...data }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  const countryOptions = countries?.list
    .map((country) => ({
      label: country.title,
      value: country.id,
    }))
    .sort((a, b) => String(a.label).localeCompare(String(b.label)));

  const rules = {
    first_name: [{ required: true }],
    last_name: [{ required: true }],
    country: [{ required: true }],
  };

  const formProps = generateFormProps(
    rules,
    { error },
    {
      first_name: 'First name',
      last_name: 'Last name',
      country: 'Country',
    }
  );

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: 'unset', padding: '0 4px' }}
        loading={upgradeLoading}
      >
        Upgrade to level 1
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title={`Set role to user`}
        cancelText={'Close'}
        okText={'Confirm'}
        destroyOnClose
      >
        <Divider type="horizontal" />
        <ProForm
          form={form}
          onFinish={async (values) => onFinish(values)}
          submitter={false}
          dateFormatter={false}
        >
          <Flex dir="row" justify="space-between" align="center">
            <Typography>Username : </Typography>
            <Typography style={{ fontWeight: 'bold' }}>{username}</Typography>
          </Flex>
          <br />
          <ProFormText {...formProps.first_name} />
          <ProFormText {...formProps.last_name} />
          <ProFormSelect
            {...formProps.country}
            showSearch
            options={countryOptions}
          />
        </ProForm>
      </Modal>
    </>
  );
};
export default UpgradeToLvl1Modal;
