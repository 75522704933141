import { Button, Modal } from 'antd';
import { useWalletApprove } from 'hooks';
import React from 'react';

type ApproveWalletProps = {
  itemID: string;
  onApprove: () => void;
};

const ApproveWallet: React.FC<ApproveWalletProps> = ({ itemID, onApprove }) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: approve, isLoading: approveLoading } =
    useWalletApprove();

  const onFinish = async () => {
    approve({ address_id: itemID }).then(() => {
      setIsModalOpen(false);
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: 'unset', padding: '0 4px' }}
        loading={approveLoading}
      >
        Approve
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => {
          onFinish().then(() => onApprove());
        }}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Approve Wallet"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        Do you want approve wallet?
      </Modal>
    </>
  );
};
export default ApproveWallet;
