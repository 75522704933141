import { Tag } from 'antd';
import { Columns, Profile, User } from 'types';

const columns: Columns<User> = (actions, roles) => [
  {
    title: 'Username',
    dataIndex: 'username',
  },
  {
    title: 'Role',
    dataIndex: ['role', 'title'],
    valueEnum: roles,
    search: {
      transform: (value: any[]) => {
        return {
          role: value,
        };
      },
    },
  },
  {
    title: 'Identifier',
    dataIndex: 'identifier_number',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    hideInSearch: true,
  },
  {
    title: 'Full name',
    dataIndex: ['profile', 'first_name'],
    render: (
      _: string,
      { profile, email }: { email: string; profile: Profile }
    ) => {
      return `${
        profile.first_name
          ? profile.first_name + ' ' + profile.last_name
          : email
      }`;
    },
    hideInSearch: true,
  },
  {
    title: 'Status',
    dataIndex: 'is_suspended',
    valueEnum: {
      false: { text: <Tag color="green">Active</Tag> },
      true: { text: <Tag color="red">Blocked</Tag> },
    },
    hideInSearch: true,
  },
  {
    title: 'Actions',
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
