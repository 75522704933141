import { ErrorResponse, ListResponse, User } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyUsers = ({ params }: { params: any }) => {
  return api.get<ListResponse<User>, ErrorResponse, any>(url.users, {
    params,
  });
};

export { queyUsers };
