import { url } from "statics";
import { Contact, ErrorResponse, ListResponse } from "types";
import { api } from "utils";

const queyContacts = ({ params, userId }: { params: any; userId: string }) => {
  return api.get<ListResponse<Contact>, ErrorResponse, any>(
    `${url.contacts}?user=${userId}`,
    {
      params,
    }
  );
};

export { queyContacts };
