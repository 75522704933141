import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import { ErrorResponse } from 'types';

const useLogout = (): UseMutationResult<[], ErrorResponse> =>
  useMutation((data) => api.post(url.logout, data));

export default useLogout;
