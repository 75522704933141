import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import {
  ErrorResponse,
  VerificationLoginPayload,
  VerificationLoginResponse,
} from 'types';

const useVerificationLogin = ({
  token,
}: {
  token: string;
}): UseMutationResult<
  VerificationLoginResponse,
  ErrorResponse,
  VerificationLoginPayload
> =>
  useMutation((data) =>
    api.post(url.verificationLogin, data, { headers: { 'csrf-token': token } })
  );

export default useVerificationLogin;
