interface TableListPagination {
  total: number;
  pageSize: number;
  current: number;
}

export interface TableListData<T = unknown> {
  list: T[];
  pagination: Partial<TableListPagination>;
}

declare type Response<T = unknown> = {
  data: T | undefined;
  success?: boolean;
  message?: string;
  error?: Record<string, string[]>;
};

declare type ProTableService<T = unknown> = (
  args?: Record<string, any>
) => Promise<Response<TableListData<T>>>;

const ProTableRequest =
  <T>(service: any, setList?: any) =>
  (args?: Record<string, any>) =>
  async (params: Record<string, any>, sorter: any, filter: any) => {
    const queries: Record<string, any> = {
      ...sorter,
      ...filter,
      ...params,
    };
    const data = await service({
      params: queries,
    });

    if (setList) {
      setList(data?.list);
    }
    return {
      data: data?.list,
      total: data?.pagination ? data?.pagination.total : 100000,
    };
  };

export default ProTableRequest;
