import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';

import type { ErrorResponse } from 'types';

const useBalance = ({
  id,
}: {
  id: string;
}): UseQueryResult<{ balance: number }, ErrorResponse> => {
  return useQuery([url.balance(id)]);
};

export default useBalance;
