import { Button, Form, Input } from 'antd';
import { LoginPayload } from 'types';
import { useLogin } from 'hooks';

const Login: React.FC<{
  setLoginState: ({
    token,
    username,
  }: {
    token: string;
    username: string;
  }) => void;
}> = ({ setLoginState }) => {
  const [form] = Form.useForm();

  const { mutateAsync, isLoading } = useLogin();

  const onFinish = (values: LoginPayload) => {
    mutateAsync(values).then((res) => {
      setLoginState({ token: res.token, username: values.username });
    });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        label="Email"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
