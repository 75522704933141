import axios, { Axios, AxiosPromise, AxiosRequestConfig } from 'axios';
import notification from './notification';
import { routes } from 'statics';
export interface CustomAxiosInstance extends Axios {
  (config: AxiosRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRequestConfig): AxiosPromise;
}
const api: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },

  transformRequest: [
    (data, headers) => {
      const token = localStorage.getItem('magix-admin-token');
      if (token && headers) {
        headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
      }
      return JSON.stringify(data);
    },
  ],
});

api.interceptors.response.use(
  (response) => {
    if (response.config.method === 'post' || response.config.method === 'put') {
      if (response.config.url) {
        notification.success({
          message: response.data.message,
        });
      }
    }
    return response.data.data;
  },
  (error) => {
    const { status, data } = error.response ?? { status: null };
    notification.error({
      message: data?.message,
    });
    if (status === 401) {
      localStorage.removeItem('magix-admin-token');
      window.location.replace(routes.login.url);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default api;
