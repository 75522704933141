import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormTextArea,
  ProFormTimePicker,
} from '@ant-design/pro-components';
import { Col, Divider, Flex, Form, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import { useCurrencies } from 'hooks';
import { LDContract, LDContractFormData } from 'types';
import { generateFormProps } from 'utils';

type LDContractFormProps = {
  form: FormInstance;
  data?: LDContract;
  onFinish: (data: LDContractFormData) => void;
  error: any;
  username: string;
};

const LDContractForm: React.FC<LDContractFormProps> = ({
  form,
  data,
  error,
  username,
  onFinish,
}) => {
  const rules = {
    nick_name: [{ required: true }],
    markdown: [
      {
        required: true,
        type: 'number',
      },
    ],
    markup: [
      {
        required: true,
        type: 'number',
      },
    ],
    payment_methods: [{ required: true }],
    fee_type: [{ required: true }],
    pay_with_currencies: [{ required: true }],
    pay_out_currencies: [{ required: true }],
    allowed_currencies: [{ required: true }],
    bank_account: [{ required: true }],
    addresses: [{ required: true }],
    working_hours: [{ required: true }],
    bank_name: [{ required: true }],
    holder_name: [{ required: true }],
    account_id: [{ required: true }],
    IBAN: [{ required: false }],
    bank_description: [{ required: false }],
    description: [{ required: false }],
  };

  const formProps = generateFormProps(
    rules,
    { error },
    {
      nick_name: 'Nick name',
      markdown: 'Markdown',
      markup: 'Markup',
      payment_methods: 'Payment methods',
      fee_type: 'Fee type',
      pay_with_currencies: 'Pay with currencies',
      pay_out_currencies: 'Pay out currencies',
      allowed_currencies: 'Allowed currencies',
      bank_account: 'Bank account',
      working_hours: 'Working hours',
      addresses: 'Addresses',
      bank_name: 'Bank name',
      holder_name: 'Holder name',
      account_id: 'Account ID',
      IBAN: 'IBAN',
      bank_description: 'Bank description',
      description: 'Description',
    }
  );

  const pairDays: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  const Divide = () => {
    return (
      <Divider type="vertical" style={{ marginBottom: 20, height: 'auto' }} />
    );
  };

  const { data: currencies } = useCurrencies();

  const currenciesOptions = currencies?.list.map((currency) => ({
    label: currency.symbol,
    value: currency.id,
  }));

  const working_hours_value = Form.useWatch('working_hours', form);

  return (
    <ProForm
      form={form}
      onFinish={async (values) => onFinish(values)}
      submitter={false}
      dateFormatter={false}
      initialValues={
        data
          ? {
              ...data,
              bank_name: data?.bank_account?.bank_name,
              holder_name: data?.bank_account?.holder_name,
              IBAN: data?.bank_account?.IBAN,
              account_id: data?.bank_account?.account_id,
              bank_description: data?.bank_account?.description,
            }
          : {
              addresses: [{ city: '', address: '' }],
              working_hours: [
                { day: 0, is_open: false },
                { day: 1, is_open: false },
                { day: 2, is_open: false },
                { day: 3, is_open: false },
                { day: 4, is_open: false },
                { day: 5, is_open: false },
                { day: 6, is_open: false },
              ],
            }
      }
    >
      <Flex dir="row" justify="space-between" align="center">
        <Typography>Username : </Typography>
        <Typography style={{ fontWeight: 'bold' }}>{username}</Typography>
      </Flex>
      <br />
      <ProFormText {...formProps.nick_name} />
      <ProFormSelect
        {...formProps.fee_type}
        options={[
          { label: 'Manual', value: 'MANUAL' },
          { label: 'System', value: 'SYSTEM' },
        ]}
      />
      <ProFormSelect
        {...formProps.payment_methods}
        options={[
          { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
          { label: 'Cash', value: 'CASH' },
        ]}
        mode="multiple"
      />
      <ProFormSelect
        {...formProps.pay_with_currencies}
        options={currenciesOptions}
        mode="multiple"
      />
      <ProFormSelect
        {...formProps.pay_out_currencies}
        options={currenciesOptions}
        mode="multiple"
      />
      <ProFormSelect
        {...formProps.allowed_currencies}
        options={currenciesOptions}
        mode="multiple"
      />
      <ProFormDigit {...formProps.markup} min={0} style={{ width: '100%' }} />
      <ProFormDigit {...formProps.markdown} min={0} style={{ width: '100%' }} />
      <Divider type="horizontal" style={{ borderColor: 'green' }} />
      <Typography
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: 20,
          color: 'orange',
        }}
      >
        Please enter Local depositor working hours by GMT time
      </Typography>
      <ProFormList
        {...formProps.working_hours}
        deleteIconProps={false}
        copyIconProps={false}
        creatorButtonProps={false}
        containerStyle={{ width: '100%' }}
      >
        {({ key }) => {
          return (
            <Row
              key={key}
              align="middle"
              justify="space-between"
              style={{ width: '100%' }}
            >
              <Col>
                <Typography style={{ marginBottom: 20 }}>
                  {pairDays[key]}
                </Typography>
              </Col>
              <Col>
                <Row>
                  <ProFormSwitch name="is_open" />
                  <Divide />
                  <ProFormTimePicker
                    name="open_time"
                    placeholder="Open time"
                    fieldProps={{
                      format: 'HH:mm',
                      disabled: !working_hours_value?.[key]?.is_open,
                    }}
                  />
                  <Divide />
                  <ProFormTimePicker
                    name="close_time"
                    placeholder="Close time"
                    fieldProps={{
                      format: 'HH:mm',
                      disabled: !working_hours_value?.[key]?.is_open,
                      disabledDate: (date) =>
                        date.isBefore(working_hours_value?.[key]?.open_time),
                    }}
                  />
                </Row>
              </Col>
            </Row>
          );
        }}
      </ProFormList>
      <Divider type="horizontal" style={{ borderColor: 'green' }} />
      <ProFormList
        {...formProps.addresses}
        copyIconProps={false}
        containerStyle={{ width: '100%' }}
        min={1}
      >
        {({ key }) => {
          return (
            <Col key={key} style={{ width: '100%' }}>
              <Divider
                type="horizontal"
                style={{ margin: '10px 0', borderColor: 'lightgreen' }}
                orientation="left"
              >
                {key + 1}
              </Divider>
              <ProFormText
                rules={[{ required: true }]}
                help="Please enter city"
                name="city"
                placeholder="city"
              />
              <ProFormTextArea
                rules={[{ required: true }]}
                help="Please enter address"
                name="address"
                placeholder="address"
              />
            </Col>
          );
        }}
      </ProFormList>
      <Divider type="horizontal" style={{ borderColor: 'green' }} />
      <ProFormTextArea {...formProps.description} style={{ width: '100%' }} />
      <Divider type="horizontal" style={{ borderColor: 'green' }} />
      <Typography style={{ fontSize: '20px', marginBottom: 20 }}>
        Bank Account Data
      </Typography>
      <ProFormText {...formProps.bank_name} style={{ width: '100%' }} />
      <ProFormText {...formProps.holder_name} style={{ width: '100%' }} />
      <ProFormText {...formProps.account_id} style={{ width: '100%' }} />
      <ProFormText {...formProps.IBAN} style={{ width: '100%' }} />
      <ProFormTextArea
        {...formProps.bank_description}
        style={{ width: '100%' }}
      />
    </ProForm>
  );
};
export default LDContractForm;
