import React from "react";
import { useParams } from "react-router-dom";
import { Flex } from "antd";
import { useProposal } from "hooks";
import { TotalInfo } from "./components";

const Proposal: React.FC = () => {
  const { proposalId } = useParams();

  const {
    data: proposal,
    isLoading: proposalLoading,
    refetch: refetchProposal,
  } = useProposal({
    id: proposalId || "",
  });

  return (
    <Flex vertical gap={8} style={{ paddingBottom: 20 }}>
      <TotalInfo
        proposal={proposal}
        proposalLoading={proposalLoading}
        refetchProposal={refetchProposal}
      />
    </Flex>
  );
};

export default Proposal;
