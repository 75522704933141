import type { MutableRefObject } from 'react';
import type { ActionType } from '@ant-design/pro-table';

const reloadTable = (actionRef: MutableRefObject<ActionType | undefined>) => {
  if (actionRef?.current) {
    actionRef.current.reload();
  }
};

export default reloadTable;
