import {
  ProTable as AntdProTable,
  ProTableProps,
} from '@ant-design/pro-components';
import usePagination from 'hooks/usePagination';

function ProTable<T extends Record<string, any>>(
  props: ProTableProps<T, any, any> & {
    excelExport?: boolean;
  }
): JSX.Element {
  const [pageSize, onPaginationChange] = usePagination();

  return (
    <AntdProTable<T>
      {...props}
      sticky
      pagination={
        props.pagination !== false
          ? {
              pageSize,
              onChange: onPaginationChange,
              ...props?.pagination,
            }
          : false
      }
      showSorterTooltip={false}
    />
  );
}

export default ProTable;
