import { Card, Descriptions, Flex, Image, Typography } from 'antd';
import { Transaction } from 'types';

type IncomingInfoProps = {
  transaction?: Transaction;
};

const IncomingInfo: React.FC<IncomingInfoProps> = ({ transaction }) => {
  return (
    <Card>
      <Descriptions
        bordered
        layout="vertical"
        title="Incoming information"
        items={[
          {
            key: '0',
            label: 'Title',
            children: (
              <Typography>
                {transaction?.transaction_method?.pay_currency?.title}
              </Typography>
            ),
          },
          {
            key: '1',
            label: 'Amount',
            children: (
              <Typography>
                {transaction?.form.deposit_bank_transfer?.pay_amount +
                  ' ' +
                  transaction?.transaction_method?.pay_currency?.symbol}
              </Typography>
            ),
          },
          {
            key: '2',
            label: 'File',
            children: (
              <Flex gap={8}>
                {transaction?.form?.deposit_bank_transfer
                  ?.bank_receipt_file && (
                  <Image
                    preview
                    src={
                      transaction?.form?.deposit_bank_transfer
                        ?.bank_receipt_file
                    }
                    alt="symbol"
                    style={{ width: '200px' }}
                  />
                )}
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default IncomingInfo;
