import React from 'react';
import { queyUserBankAccount } from './services';
import ProTable from 'components/ProTable';
import columns from './columns';
import type { BankAccount } from 'types';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { ProTableRequest } from 'utils';
import { useParams } from 'react-router-dom';
import { useUser } from 'hooks';

const UserBankAccount: React.FC = () => {
  const { userId } = useParams();

  const { data: user } = useUser({ id: userId || '' });

  const userBankAccountRequest = ProTableRequest<BankAccount>(
    ({ params }: { params: any }) =>
      queyUserBankAccount({ params, userId: userId || '' })
  )({});

  return (
    <ProTable<BankAccount>
      headerTitle={
        <Flex style={{ width: '100%' }} align="center">
          <Title level={5}>
            {(user?.username
              ? user?.username
              : user?.profile.first_name && user?.profile.last_name
              ? user?.profile.first_name + ' ' + user?.profile.last_name
              : '') + ' bank accounts'}
          </Title>
        </Flex>
      }
      columns={columns()}
      request={userBankAccountRequest}
      options={false}
      search={false}
    />
  );
};

export default UserBankAccount;
