import { ErrorResponse, ListResponse, Wallet } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyUserWallets = ({
  params,
  userId,
}: {
  params: any;
  userId: string;
}) => {
  return api.get<ListResponse<Wallet>, ErrorResponse, any>(url.wallets, {
    params: {
      ...params,
      user: userId,
    },
  });
};

export { queyUserWallets };
