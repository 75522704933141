import React from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'antd';
import { useTransaction } from 'hooks';
import {
  DestinationInfo,
  SourceInfo,
  TotalInfo,
  IncomingInfo,
  DestinationBankAccountInfo,
  SourceBankAccountInfo,
} from './components';

const Transaction: React.FC = () => {
  const { transactionId } = useParams();

  const {
    data: transaction,
    isLoading: transactionLoading,
    refetch: refetchTransaction,
  } = useTransaction({
    id: transactionId || '',
  });

  return (
    <Flex vertical gap={8} style={{ paddingBottom: 20 }}>
      <TotalInfo
        transaction={transaction}
        transactionLoading={transactionLoading}
        refetchTransaction={refetchTransaction}
      />
      <SourceInfo
        transaction={transaction}
        transactionLoading={transactionLoading}
      />
      <DestinationInfo
        transaction={transaction}
        transactionLoading={transactionLoading}
      />
      {transaction?.form.deposit_bank_transfer &&
        transaction?.transaction_method.pay_currency && (
          <IncomingInfo transaction={transaction} />
        )}
      {transaction?.destination_bank_account && (
        <DestinationBankAccountInfo transaction={transaction} />
      )}
      {transaction?.source_bank_account && (
        <SourceBankAccountInfo transaction={transaction} />
      )}
    </Flex>
  );
};

export default Transaction;
