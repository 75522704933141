import { ErrorResponse, ListResponse, BankAccount } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyUserBankAccount = ({
  params,
  userId,
}: {
  params: any;
  userId: string;
}) => {
  return api.get<ListResponse<BankAccount>, ErrorResponse, any>(
    url.bankAccounts,
    {
      params: {
        // ...params,
        user: userId,
      },
    }
  );
};

export { queyUserBankAccount };
