import { Button, Divider, Form, Modal } from 'antd';
import { useUpdateLDContract } from 'hooks';
import React from 'react';
import { LDContract, LDContractFormData } from 'types';
import LDContractForm from '../LDContractForm';

type UpdateLdContractModalProps = {
  username: string;
  userId: string;
  data: LDContract;
  refresh: () => void;
};

const UpdateLdContractModal: React.FC<UpdateLdContractModalProps> = ({
  username,
  userId,
  data,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const {
    mutateAsync: updateLDContract,
    isLoading: upgradeLoading,
    error,
  } = useUpdateLDContract({ id: data.id });

  const onFinish = (data: LDContractFormData) => {
    const payload = {
      nick_name: data.nick_name,
      markdown: data.markdown,
      markup: data.markup,
      payment_methods: data.payment_methods,
      fee_type: data.fee_type,
      pay_with_currencies: data.pay_with_currencies,
      pay_out_currencies: data.pay_out_currencies,
      addresses: data.addresses,
      working_hours: data.working_hours,
      allowed_currencies: data.allowed_currencies,
      description: data.description,
      bank_account: {
        bank_name: data.bank_name,
        holder_name: data.holder_name,
        account_id: data.account_id,
        IBAN: data.IBAN,
        description: data.bank_description,
      },
      user: userId,
    };
    updateLDContract(payload).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: 'unset', padding: '0 4px' }}
        loading={upgradeLoading}
      >
        Update LD contract
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Upgrade user to LD"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        <Divider type="horizontal" />
        <LDContractForm
          form={form}
          data={data}
          onFinish={onFinish}
          error={error}
          username={username}
        />
      </Modal>
    </>
  );
};
export default UpdateLdContractModal;
