import { ErrorResponse, ListResponse, Whitelist } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyWalletWhitelist = ({
  params,
  userId,
}: {
  params: any;
  userId: string;
}) => {
  return api.get<ListResponse<Whitelist>, ErrorResponse, any>(url.whitelist, {
    params: {
      // ...params,
      user: userId,
    },
  });
};

export { queyWalletWhitelist };
