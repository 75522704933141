import { Flex, Tag, Typography } from 'antd';
import { Columns, Fee, Transaction, TransactionMethod } from 'types';

const columns: Columns<Transaction> = ({
  actions,
  role,
}: {
  actions: any;
  role: string;
}) => [
  {
    title: 'Type',
    dataIndex: ['transaction_method', 'type'],
    valueEnum: {
      DEPOSIT: { text: <Tag color="green">Deposit</Tag> },
      WITHDRAW: { text: <Tag color="red">Withdraw</Tag> },
      TRANSFER: { text: <Tag color="purple">Transfer</Tag> },
      SWAP: { text: <Tag color="geekblue">Swap</Tag> },
    },
    render: (
      _: string,
      {
        transaction_method,
      }: {
        transaction_method: TransactionMethod;
      }
    ) => {
      const isLDTr = ['DEPOSIT_LD', 'WITHDRAW_LD'].includes(
        transaction_method.category
      );
      const isBrokerTr = ['DEPOSIT_BROKER', 'WITHDRAW_BROKER'].includes(
        transaction_method.category
      );
      const isIntermediary =
        (role === 'LD' && isLDTr) || (role === 'BROKER' && isBrokerTr);

      const transactionType = {
        DEPOSIT: {
          color: isIntermediary ? 'red' : 'green',
          text: isIntermediary ? 'Withdraw' : 'Deposit',
        },
        WITHDRAW: {
          color: isIntermediary ? 'green' : 'red',
          text: isIntermediary ? 'Deposit' : 'Withdraw',
        },
        TRANSFER: { color: 'purple', text: 'Transfer' },
        SWAP: { color: 'geekblue', text: 'Swap' },
      };

      return (
        <Flex>
          <Tag color={transactionType[transaction_method.type].color}>
            {transactionType[transaction_method.type].text}
          </Tag>
        </Flex>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (
      _: string,
      {
        amount,
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        amount: number;
        source_wallet: Transaction['source_wallet'];
        destination_wallet: Transaction['destination_wallet'];
        transaction_method: TransactionMethod;
      }
    ) => {
      const trType = transaction_method.type;
      const symbol = {
        DEPOSIT: destination_wallet?.currency.symbol,
        WITHDRAW: source_wallet?.currency.symbol,
        TRANSFER: source_wallet?.currency.symbol,
        SWAP: source_wallet?.currency.symbol,
      };

      return (
        <Typography.Text>
          {amount > 0 ? amount.toFixed(2) : amount}
          <Typography.Text
            style={{ fontSize: '10px', paddingLeft: 2, color: 'green' }}
          >
            {symbol[trType]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: 'Destination amount',
    dataIndex: 'destination_amount',
    render: (
      _: string,
      {
        destination_amount,
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        destination_amount: number;
        source_wallet: Transaction['source_wallet'];
        destination_wallet: Transaction['destination_wallet'];
        transaction_method: TransactionMethod;
      }
    ) => {
      const trType = transaction_method.type;
      const symbol = {
        DEPOSIT: destination_wallet?.currency.symbol,
        WITHDRAW: source_wallet?.currency.symbol,
        TRANSFER: destination_wallet?.currency.symbol,
        SWAP: destination_wallet?.currency.symbol,
      };

      return (
        <Typography.Text>
          {destination_amount > 0
            ? destination_amount.toFixed(2)
            : destination_amount}
          <Typography.Text
            style={{ fontSize: '10px', paddingLeft: 2, color: 'green' }}
          >
            {symbol[trType]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    render: (
      _: string,
      {
        source_wallet,
        destination_wallet,
        fee,
        transaction_method,
      }: {
        source_wallet: Transaction['source_wallet'];
        destination_wallet: Transaction['destination_wallet'];
        fee: Fee;
        transaction_method: TransactionMethod;
      }
    ) => {
      const trType = transaction_method.type;
      const symbol = {
        DEPOSIT: destination_wallet?.currency.symbol,
        WITHDRAW: source_wallet?.currency.symbol,
        TRANSFER: source_wallet?.currency.symbol,
        SWAP: source_wallet?.currency.symbol,
      };

      return (
        <Typography.Text>
          {fee.fee_amount || 0}
          <Typography.Text
            style={{ fontSize: '10px', paddingLeft: 2, color: 'green' }}
          >
            {symbol[trType]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    valueEnum: {
      INIT: { text: 'Init', status: 'Default' },
      AUTHORIZED: { text: 'Authorized', status: 'Processing' },
      UNAUTHORIZED: { text: 'Unauthorized', status: 'Error' },
      CLEARED: { text: 'Cleared', status: 'Processing' },
      NOT_CLEARED: { text: 'Not cleared', status: 'Error' },
      APPROVED: { text: 'Approved', status: 'Processing' },
      DENIED: { text: 'Denied', status: 'Error' },
      SETTLING: { text: 'Settling', status: 'Processing' },
      FAILED: { text: 'Failed', status: 'Error' },
      SETTLED: { text: 'Settled', status: 'Success' },
      CANCELED: { text: 'Canceled', status: 'Error' },
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    valueType: 'dateTime',
    hideInSearch: true,
  },
  {
    title: 'Actions',
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
