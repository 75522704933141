import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse } from 'types';

const useBlockUser = (): UseMutationResult<
  {},
  ErrorResponse,
  { user_id: string; is_suspended: boolean }
> =>
  useMutation(({ user_id, is_suspended }) =>
    api.put(url.blockUser(user_id), { is_suspended })
  );

export default useBlockUser;
