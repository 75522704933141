import { MenuOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Layout, Row, Typography, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutModal from '../LogoutModal';

type HeaderProps = {
  title: string;
  collapsed: boolean;
  setCollapsed: () => void;
  setOpenSidebar: () => void;
};

const Header: React.FC<HeaderProps> = ({
  title,
  collapsed,
  setCollapsed,
  setOpenSidebar,
}) => {
  const { Header: AntdHeader } = Layout;
  const { Title } = Typography;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigation = useNavigate();

  const menuItem = [
    { title: 'dashboard', onClick: () => navigation('/dashboard') },
    title !== 'dashboard'
      ? { title: title, onClick: () => navigation(`/dashboard/${title}`) }
      : {},
  ];

  return (
    <AntdHeader style={{ padding: 0, background: colorBgContainer }}>
      <Row
        align="middle"
        style={{
          width: '100%',
          height: '100%',
          padding: '0 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col>
          <Row align="middle" gutter={[4, 4]}>
            <Col className="toggle-sidebar-button">
              <Button
                type="text"
                style={{
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  padding: 0,
                }}
                onClick={setOpenSidebar}
              >
                <MenuOutlined />
              </Button>
            </Col>
            <Col>
              <Breadcrumb items={menuItem} />
              <Title
                level={3}
                style={{ margin: 0, textTransform: 'capitalize' }}
              >
                {title}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col>
          <LogoutModal />
        </Col>
      </Row>
    </AntdHeader>
  );
};
export default Header;
