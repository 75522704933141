import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProfile } from './queries';
import useLocalStorageState from 'use-local-storage-state';
import { routes } from 'statics';

const usePrivate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token] = useLocalStorageState<string>('magix-admin-token');

  const [fetchProfile, setFetchProfile] = React.useState<boolean>(false);

  const profileQuery = useProfile({ enabled: fetchProfile });

  React.useEffect(() => {
    if (!token && !location.pathname.includes('auth')) {
      navigate(routes.login.url);
    }

    if (token) {
      setFetchProfile(true);
    }
  }, []);

  return profileQuery;
};

export default usePrivate;
