import React from "react";
import { queyTransactions } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import { Transaction, User } from "types";
import { useNavigate } from "react-router-dom";
import { Button, Flex } from "antd";
import Title from "antd/es/typography/Title";
import { notification, ProTableRequest, reloadTable } from "utils";
import { ActionType } from "@ant-design/pro-components";
import {
  ApproveDeposit,
  ApproveWithdraw,
  ApproveWithdrawCrypto,
  RejectDeposit,
} from "pages/UserTransactions/components";
import { TableButton } from "components";
import { routes } from "statics";
import exportPDF from "utils/exportPDF";

const Transactions: React.FC = () => {
  const navigate = useNavigate();
  const actionRef = React.useRef<ActionType>();
  const [list, setList] = React.useState<any[]>([]);

  const transactionsRequest = ProTableRequest<User>(
    queyTransactions,
    setList
  )();

  const exportHandler = () => {
    exportPDF({
      data: list.map((item: Transaction) => ({
        type: item.transaction_method.type,
        category: item.transaction_method.category,
        amount: item.amount,
        destination_amount: item.destination_amount,
        fee: item.fee,
        status: item.status,
        created_at: item.created_at,
      })),
      fileName: `transactions-${new Date().getTime()}`,
    });
    notification.success({
      message: "Export was successful",
    });
  };

  const actions = (_: any, item: Transaction) => [
    item.transaction_method.category === "DEPOSIT_BANK_TRANSFER" &&
      item.status === "CLEARED" && (
        <>
          <ApproveDeposit
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectDeposit
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    item.transaction_method.category === "WITHDRAW_BANK_TRANSFER" &&
      item.status === "APPROVED" && (
        <ApproveWithdraw
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
    item.transaction_method.category === "WITHDRAW_CRYPTO" &&
      item.status === "CLEARED" && (
        <ApproveWithdrawCrypto
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
    <TableButton
      title="Detail"
      onClick={() => navigate(routes.transaction.href(item.id))}
    />,
  ];

  return (
    <ProTable<User>
      headerTitle={
        <Flex style={{ width: "100%" }} align="center" justify="space-between">
          <Title level={5}>Transactions List</Title>
        </Flex>
      }
      toolBarRender={() => [
        list.length > 0 && (
          <Button onClick={() => exportHandler()}>Export excel</Button>
        ),
      ]}
      columns={columns({ actions })}
      request={transactionsRequest}
      options={false}
    />
  );
};

export default Transactions;
