import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type {
  ErrorResponse,
  UpgradeToLDResponse,
  UpgradeToLDPayload,
} from 'types';

const useUpgradeToLD = (): UseMutationResult<
  UpgradeToLDResponse,
  ErrorResponse,
  UpgradeToLDPayload
> => useMutation((data) => api.post(url.upgradeToLD, data));

export default useUpgradeToLD;
