import { Tag } from 'antd';
import { Columns, BankAccount } from 'types';

const columns: Columns<BankAccount> = (actions) => [
  {
    title: 'Title',
    dataIndex: 'title',
    hideInSearch: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    valueEnum: {
      VERIFIED: { text: <Tag color="green">Verified</Tag> },
      NOT_VERIFIED: { text: <Tag color="red">Not verified</Tag> },
    },
    hideInSearch: true,
  },
  {
    title: 'Wallet address',
    dataIndex: 'wallet_address',
    hideInSearch: true,
  },
  {
    title: 'Actions',
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
