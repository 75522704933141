import React from 'react';
import { blue, green, grey, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification/interface';


const SuccessIcon = React.createElement(CheckCircleOutlined, {
  style: { color: grey[13] },
});
const ErrorIcon = React.createElement(CloseCircleOutlined, {
  style: { color: grey[13] },
});

export declare interface Notification {
  message?: string;
  description?: string;
  placement?: NotificationPlacement;
}

const errorNotification = ({
  message,
  description,
  placement = 'topRight',
}: Notification) => {
  notification.error({
    message,
    description,
    placement,
    style: {
      backgroundColor: red[4],
    },
    duration: 5,
    icon: ErrorIcon,
  });
};

const successNotification = ({
  message,
  description,
  placement = 'topLeft',
}: Notification) => {
  notification.open({
    message,
    description,
    placement,
    style: {
      backgroundColor: green[4],
    },
    duration: 3,
    icon: SuccessIcon,
  });
};
const completeNotification = ({
  message,
  description,
  placement = 'topLeft',
}: Notification) => {
  notification.open({
    message,
    description,
    placement,
    style: {
      backgroundColor: blue[4],
    },
    duration: 5,
    icon: SuccessIcon,
  });
};

export default {
  error: errorNotification,
  success: successNotification,
  complete: completeNotification,
};
