import { Tag, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { routes } from "statics";
import {
  Columns,
  Proposal,
  Transaction,
  TransactionMethod,
  Wallet,
} from "types";

const columns: Columns<Proposal> = ({ actions }: { actions: any }) => [
  {
    title: "User",
    dataIndex: "user",
    render: (
      _: string,
      { transaction_method }: { transaction_method: TransactionMethod }
    ) => {
      const wallet: Record<string, React.ReactElement> = {
        DEPOSIT_LD: <Tag color="green">Deposit</Tag>,
        WITHDRAW_LD: <Tag color="red">Withdraw</Tag>,
      };
      return wallet[transaction_method.category];
    },
    hideInSearch: true,
  },
  {
    title: "User",
    dataIndex: "user",
    render: (
      _: string,
      {
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        source_wallet: Transaction["source_wallet"];
        destination_wallet: Transaction["destination_wallet"];
        transaction_method: TransactionMethod;
      }
    ) => {
      const wallet: Record<string, Wallet> = {
        DEPOSIT_LD: destination_wallet,
        WITHDRAW_LD: source_wallet,
      };
      return (
        <Link href={routes.client.href(wallet[transaction_method.category].id)}>
          <Typography.Text style={{ textDecoration: "underline" }}>
            {wallet[transaction_method.category].user.profile.first_name +
              " " +
              wallet[transaction_method.category].user.profile.last_name}
          </Typography.Text>
        </Link>
      );
    },
    hideInSearch: true,
  },
  {
    title: "LD",
    dataIndex: "ld_user",
    render: (
      _: string,
      {
        destination_wallet,
        source_wallet,
        transaction_method,
      }: {
        destination_wallet: Transaction["destination_wallet"];
        source_wallet: Transaction["destination_wallet"];
        transaction_method: TransactionMethod;
      }
    ) => {
      const wallet: Record<string, Wallet> = {
        DEPOSIT_LD: source_wallet,
        WITHDRAW_LD: destination_wallet,
      };
      return (
        <Link href={routes.client.href(wallet[transaction_method.category].id)}>
          <Typography.Text style={{ textDecoration: "underline" }}>
            {wallet[transaction_method.category].user.profile.first_name +
              " " +
              wallet[transaction_method.category].user.profile.last_name}
          </Typography.Text>
        </Link>
      );
    },
    hideInSearch: true,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (
      _: string,
      {
        amount,
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        amount: number;
        source_wallet: Transaction["source_wallet"];
        destination_wallet: Transaction["destination_wallet"];
        transaction_method: TransactionMethod;
      }
    ) => {
      const wallet: Record<string, Wallet> = {
        DEPOSIT_LD: destination_wallet,
        WITHDRAW_LD: source_wallet,
      };
      return (
        <Typography.Text>
          {amount}
          <Typography.Text
            style={{ fontSize: "10px", paddingLeft: 2, color: "green" }}
          >
            {wallet[transaction_method.category].currency.symbol}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    valueEnum: {
      INIT: { text: "Init", status: "Default" },
      PENDING: { text: "Pending", status: "Warning" },
      REJECTED: { text: "Rejected", status: "Error" },
      APPROVED: { text: "Approved", status: "Processing" },
      COMPLETED: { text: "Completed", status: "Success" },
      SETTLING: { text: "Settling", status: "Processing" },
      FAILED: { text: "Failed", status: "Error" },
      SETTLED: { text: "Settled", status: "Processing" },
      CANCELED: { text: "Canceled", status: "Error" },
    },
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    valueType: "dateTime",
    hideInSearch: true,
  },
  {
    title: "Actions",
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
