import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import { ErrorResponse, LoginPayload, LoginResponse } from 'types';

const useLogin = (): UseMutationResult<
  LoginResponse,
  ErrorResponse,
  LoginPayload
> => useMutation((data) => api.post(url.login, data));

export default useLogin;
