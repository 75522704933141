import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';

import type { ErrorResponse, ListResponse, Role } from 'types';

const useRoles = (): UseQueryResult<ListResponse<Role>, ErrorResponse> => {
  return useQuery([url.roles]);
};

export default useRoles;
