import type { ErrorResponse, ListResponse, Session } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyUserSessions = ({
  params,
  userId,
}: {
  params: any;
  userId: string;
}) => {
  return api.get<ListResponse<Session>, ErrorResponse, any>(url.sessions, {
    params: {
      ...params,
      user: userId,
    },
  });
};

export { queyUserSessions };
