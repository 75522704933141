import React from "react";
import { queyUsers } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import { User } from "types";
import { TableButton } from "components";
import { useNavigate } from "react-router-dom";
import { routes } from "statics";
import { Button, Flex, Tag } from "antd";
import Title from "antd/es/typography/Title";
import { notification, ProTableRequest } from "utils";
import { useRoles } from "hooks";
import exportPDF from "utils/exportPDF";

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [list, setList] = React.useState<any[]>([]);

  const usersRequest = ProTableRequest<User>(queyUsers, setList)();

  const { data: roles } = useRoles();

  const actions = (_: any, item: User) => [
    <TableButton
      title="Details"
      onClick={() => navigate(routes.client.href(item.id))}
    />,
  ];

  const exportHandler = () => {
    exportPDF({
      data: list.map((item: User) => ({
        username: item.username,
        role: item.role.title,
        identifier_number: item.identifier_number,
        email: item.email,
        full_name: item.profile.first_name + item.profile.last_name,
        created_at: item.created_at,
        is_suspend: item.is_suspended,
      })),
      fileName: `transactions-${new Date().getTime()}`,
    });
    notification.success({
      message: "Export was successful",
    });
  };

  return (
    <ProTable<User>
      headerTitle={
        <Flex style={{ width: "100%" }} align="center" justify="space-between">
          <Title level={5}>Users List</Title>
        </Flex>
      }
      columns={columns(
        actions,
        roles?.list.reduce(
          (o: any, key) => ({
            ...o,
            [key.id]: <Tag color="default">{key.title}</Tag>,
          }),
          {}
        ) || []
      )}
      toolBarRender={() => [
        list.length > 0 && (
          <Button onClick={() => exportHandler()}>Export excel</Button>
        ),
      ]}
      request={usersRequest}
      options={false}
    />
  );
};

export default Users;
