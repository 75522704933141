import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Modal, Typography } from 'antd';
import { useLogout } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'statics';

const LogoutModal: React.FC = () => {
  const navigate = useNavigate();
  const { mutateAsync: logout, isLoading } = useLogout();
  const [isOpenLogoutModal, setIsOpenLogoutModal] = React.useState(false);
  const items = [
    {
      key: '1',
      label: (
        <Flex gap={8} onClick={() => setIsOpenLogoutModal(true)}>
          <LogoutOutlined style={{ color: 'red' }} />
          <Typography
            style={{ margin: 0, textTransform: 'capitalize', color: 'red' }}
          >
            Logout
          </Typography>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomRight">
        <Button
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: 0,
          }}
        >
          <UserOutlined style={{ fontSize: '120%' }} />
        </Button>
      </Dropdown>
      <Modal
        open={isOpenLogoutModal}
        onOk={async () =>
          logout({}).then(() => {
            localStorage.removeItem('magix-admin-token');
            navigate(routes.login.url);
          })
        }
        confirmLoading={isLoading}
        onCancel={() => setIsOpenLogoutModal(false)}
        maskClosable={false}
        title="Logout"
        cancelText="Close"
        okText="Logout"
        destroyOnClose
      >
        <Typography style={{ color: 'orange' }}>
          Do you want to logout?
        </Typography>
      </Modal>
    </>
  );
};
export default LogoutModal;
