import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const exportPDF = ({
  data,
  fileName,
}: {
  data: Record<string, any>[];
  fileName: string;
}) => {
  const TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXTENSION = '.xlsx';

  const worksheet = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const result = new Blob([excelBuffer], { type: TYPE });

  FileSaver.saveAs(result, fileName + EXTENSION);
};

export default exportPDF;
