import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import magixLogo from 'assets/images/Exchange-magix-logo.svg';
import { routes } from 'statics';

const Page404: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img src={magixLogo} alt="magix" />
      </div>
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: 20 }}>404 | Page not found</p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button type="dashed" onClick={() => navigate(routes.clients.url)}>
          Back to Dashboard
        </Button>
      </div>
    </Card>
  );
};

export default Page404;
