import { Card, Descriptions, Flex, Spin, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';

type UserInfoProps = {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  identifier_number: number;
  otp_type: string;
  address: string;
  created_at: string;
  isLoading: boolean;
};

const UserInfo: React.FC<UserInfoProps> = ({
  first_name,
  last_name,
  username,
  email,
  identifier_number,
  otp_type,
  address,
  created_at,
  isLoading,
}) => {
  const profileData = [
    {
      key: '1',
      label: 'Full name',
      children: <Typography>{first_name + last_name}</Typography>,
    },
    {
      key: '1',
      label: 'Username',
      children: (
        <Paragraph copyable={{ tooltips: false }}>{username}</Paragraph>
      ),
    },
    {
      key: '2',
      label: 'Email',
      children: <Paragraph copyable={{ tooltips: false }}>{email}</Paragraph>,
    },
    {
      key: '3',
      label: 'Identifier number',
      children: (
        <Paragraph copyable={{ tooltips: false }}>
          {identifier_number}
        </Paragraph>
      ),
    },
    {
      key: '4',
      label: 'OTP type',
      children: <Typography>{otp_type}</Typography>,
    },
    {
      key: '5',
      label: 'Address',
      children: <Typography>{address}</Typography>,
    },
    {
      key: '6',
      label: 'Joined at',
      children: (
        <Typography>{dayjs(created_at).format('YYYY-MM-DD') || '-'}</Typography>
      ),
    },
  ];
  return (
    <Card>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions
          bordered
          layout="vertical"
          title="User Info"
          items={profileData}
        />
      )}
    </Card>
  );
};
export default UserInfo;
