import {
  QueryClient,
  QueryFunctionContext,
  QueryKey,
} from '@tanstack/react-query';

import api from './api';

const defaultQueryFn = ({
  queryKey,
}: QueryFunctionContext<QueryKey, string>) => {
  return api.get(queryKey.join('/'));
};

type queryConfig = {
  staleTime?: number;
};
const createQueryClient = (config: queryConfig = {}): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24,
        queryFn: defaultQueryFn,
        retry: false,
        refetchOnWindowFocus: false,
        ...config,
      },
    },
  });

export default createQueryClient;
