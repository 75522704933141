import { ErrorResponse, ListResponse, Proposal } from "types";

import { url } from "statics";
import { api } from "utils";

const queyProposals = ({ params }: { params: any }) => {
  return api.get<ListResponse<Proposal>, ErrorResponse, any>(url.proposals, {
    params,
  });
};

export { queyProposals };
