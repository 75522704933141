import React from 'react';
import { queyUserTransactions } from './services';
import ProTable from 'components/ProTable';
import columns from './columns';
import type { Transaction } from 'types';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { ProTableRequest, reloadTable } from 'utils';
import { useParams } from 'react-router-dom';
import { useUser } from 'hooks';
import {
  ApproveDeposit,
  ApproveWithdraw,
  ApproveWithdrawCrypto,
  RejectDeposit,
} from './components';
import { ActionType } from '@ant-design/pro-components';

const UserTransactions: React.FC = () => {
  const { userId } = useParams();
  const actionRef = React.useRef<ActionType>();

  const { data: user } = useUser({ id: userId || '' });

  const userTransactionsRequest = ProTableRequest<Transaction>(
    ({ params }: { params: any }) =>
      queyUserTransactions({ params, userId: userId || '' })
  )({});

  const actions = (_: any, item: Transaction) => [
    item.transaction_method.category === 'DEPOSIT_BANK_TRANSFER' &&
      item.status === 'CLEARED' && (
        <>
          <ApproveDeposit
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectDeposit
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    item.transaction_method.category === 'WITHDRAW_BANK_TRANSFER' &&
      item.status === 'APPROVED' && (
        <ApproveWithdraw
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
    item.transaction_method.category === 'WITHDRAW_CRYPTO' &&
      item.status === 'CLEARED' && (
        <ApproveWithdrawCrypto
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
  ];

  return (
    <ProTable<Transaction>
      headerTitle={
        <Flex style={{ width: '100%' }} align="center">
          <Title level={5}>
            {(user?.username
              ? user?.username
              : user?.profile.first_name && user?.profile.last_name
              ? user?.profile.first_name + ' ' + user?.profile.last_name
              : '') + ' transactions'}
          </Title>
        </Flex>
      }
      actionRef={actionRef}
      columns={columns({ actions, role: user?.role.title })}
      request={userTransactionsRequest}
      options={false}
    />
  );
};

export default UserTransactions;
