import useLocalStorageState from 'use-local-storage-state';

const usePagination = () => {
  const [tablePageSize, setTablePageSize] =
    useLocalStorageState<any>('tablePageSize');
  const onChangePgeSize = (page: number, pageSize?: number) => {
    setTablePageSize(String(pageSize));
  };
  return [tablePageSize, onChangePgeSize];
};

export default usePagination;
