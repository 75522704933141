import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse, SetRoleResponse, SetRolePayload } from 'types';

const useSetRole = (): UseMutationResult<
  SetRoleResponse,
  ErrorResponse,
  SetRolePayload
> => useMutation((data) => api.post(url.setRole, data));

export default useSetRole;
