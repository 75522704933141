import React from 'react';
import { queyUserWallets } from './services';
import ProTable from 'components/ProTable';
import columns from './columns';
import type { Wallet } from 'types';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { ProTableRequest } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'hooks';
import { TableButton } from 'components';
import { routes } from 'statics';

const UserWallets: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { data: user } = useUser({ id: userId || '' });

  const userWalletsRequest = ProTableRequest<Wallet>(
    ({ params }: { params: any }) =>
      queyUserWallets({ params, userId: userId || '' })
  )({});

  const actions = (_: any, item: Wallet) => [
    item.type === 'CRYPTO' && (
      <TableButton
        title="Whitelist"
        onClick={() =>
          navigate(
            routes.walletWhitelist.href({
              userId: userId || '',
              walletId: item.id,
            })
          )
        }
      />
    ),
  ];

  return (
    <ProTable<Wallet>
      headerTitle={
        <Flex style={{ width: '100%' }} align="center">
          <Title level={5}>
            {(user?.username
              ? user?.username
              : user?.profile.first_name && user?.profile.last_name
              ? user?.profile.first_name + ' ' + user?.profile.last_name
              : '') + ' wallets'}
          </Title>
        </Flex>
      }
      columns={columns(actions)}
      request={userWalletsRequest}
      options={false}
    />
  );
};

export default UserWallets;
