import { Button, Modal } from 'antd';
import { useDepositReject } from 'hooks';
import React from 'react';

type RejectDepositProps = {
  transactionId: string;
  onReject: () => void;
};

const RejectDeposit: React.FC<RejectDepositProps> = ({
  transactionId,
  onReject,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: reject, isLoading: rejectLoading } = useDepositReject();

  const onFinish = async () => {
    reject({ transaction_id: transactionId }).then(() => {
      setIsModalOpen(false);
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        danger
        style={{ minHeight: 'unset', padding: '0 4px' }}
        loading={rejectLoading}
      >
        Reject
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => {
          onFinish().then(() => onReject());
        }}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Reject Deposit"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        Do you want reject transaction?
      </Modal>
    </>
  );
};
export default RejectDeposit;
