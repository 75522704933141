import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type {
  ErrorResponse,
  UpgradeToLDResponse,
  UpgradeToLDPayload,
} from 'types';

const useUpdateLDContract = ({
  id,
}: {
  id: string;
}): UseMutationResult<UpgradeToLDResponse, ErrorResponse, UpgradeToLDPayload> =>
  useMutation((data) => api.put(url.updateLDContract(id), data));

export default useUpdateLDContract;
