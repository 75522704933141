import React from "react";
import { queyProposals } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import { Proposal, User } from "types";
import { Flex } from "antd";
import Title from "antd/es/typography/Title";
import { ProTableRequest } from "utils";
import { TableButton } from "components";
import { routes } from "statics";
import { useNavigate } from "react-router-dom";

const Proposals: React.FC = () => {
  const navigate = useNavigate();

  const proposalsRequest = ProTableRequest<Proposal>(queyProposals)();

  const actions = (_: any, item: Proposal) => [
    <TableButton
      title="Detail"
      onClick={() => navigate(routes.proposal.href(item.id))}
    />,
  ];

  return (
    <ProTable<User>
      headerTitle={
        <Flex style={{ width: "100%" }} align="center" justify="space-between">
          <Title level={5}>Proposals List</Title>
        </Flex>
      }
      columns={columns({ actions })}
      request={proposalsRequest}
      options={false}
    />
  );
};

export default Proposals;
