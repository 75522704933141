import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse } from 'types';

const useWithdrawApprove = (): UseMutationResult<
  {},
  ErrorResponse,
  { transaction_id: string }
> =>
  useMutation(({ transaction_id }) =>
    api.put(url.withdrawApprove(transaction_id), {})
  );

export default useWithdrawApprove;
