import { Card, Flex, Spin, Typography } from "antd";
import { ProTable } from "components";
import { Contact } from "types";
import { ProTableRequest } from "utils";
import { queyContacts } from "./service";
import columns from "./columns";
import React from "react";

type ContactInfoProps = {
  userId: string;
  isLoading: boolean;
};

const ContactInfo: React.FC<ContactInfoProps> = ({ userId, isLoading }) => {
  const contactsRequest = ProTableRequest<Contact>(
    ({ params }: { params: any }) =>
      queyContacts({ params, userId: userId || "" })
  )();

  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <ProTable
          headerTitle={
            <Typography.Text
              style={{
                padding: "20px 0",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Contact Information
            </Typography.Text>
          }
          columns={columns()}
          request={contactsRequest}
          options={false}
          search={false}
          pagination={false}
        />
      )}
    </>
  );
};

export default ContactInfo;
