import { Tag } from 'antd';
import { Columns, BankAccount } from 'types';

const columns: Columns<BankAccount> = () => [
  {
    title: 'Title',
    dataIndex: 'title',
    hideInSearch: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    valueEnum: {
      VERIFIED: { text: <Tag color="green">Verified</Tag> },
      NOT_VERIFIED: { text: <Tag color="red">Not verified</Tag> },
    },
    hideInSearch: true,
  },
  {
    title: 'Method title',
    dataIndex: ['transaction_method', 'title'],
    hideInSearch: true,
  },
  {
    title: 'Holder name',
    dataIndex: 'holder_name',
    hideInSearch: true,
  },
  {
    title: 'ID',
    dataIndex: 'account_id',
    hideInSearch: true,
  },
  {
    title: 'Bank name',
    dataIndex: 'bank_name',
    hideInSearch: true,
  },
  {
    title: 'Phone number',
    dataIndex: 'phone',
    hideInSearch: true,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    hideInSearch: true,
  },
  {
    title: 'City',
    dataIndex: 'city',
    hideInSearch: true,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    hideInSearch: true,
  },
  {
    title: 'Swift / BIC code',
    dataIndex: 'SWIFT',
    hideInSearch: true,
  },
  {
    title: 'Bank branch name',
    dataIndex: 'bank_branch_name',
    render: (_: any, { bank_branch_name }: { bank_branch_name: string }) => {
      return bank_branch_name || '-';
    },
    hideInSearch: true,
  },
];

export default columns;
