import { Card, Descriptions, Flex, Spin, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  UpgradeToLDModal,
  UpgradeToLvl1Modal,
  SetRoleModal,
  UpdateLdContractModal,
} from '..';
import { LDContract, Role } from 'types';

type RoleInfoProps = {
  role: Role['title'] | undefined;
  userId: string;
  roleId: string;
  username: string;
  updated_at: string;
  isLoading: boolean;
  ldContract: LDContract | undefined;
  refetch: () => void;
};

const RoleInfo: React.FC<RoleInfoProps> = ({
  role,
  updated_at,
  refetch,
  userId,
  roleId,
  username,
  ldContract,
  isLoading,
}) => {
  const roleType: Record<Role['title'], string> = {
    LEVEL0: 'default',
    LEVEL1: 'geekblue',
    LEVEL2: 'blue',
    BROKER: 'purple',
    LD: 'magenta',
    SUPER_ADMIN: 'gold',
  };

  const roleData = [
    {
      key: '1',
      label: 'Role',
      children: (
        <Tag color={role ? roleType[role] : 'default'}>{role || '-'}</Tag>
      ),
    },
    {
      key: '2',
      label: 'Updated at',
      children: (
        <Typography>{dayjs(updated_at).format('YYYY-MM-DD') || '-'}</Typography>
      ),
    },
    {
      key: '3',
      label: 'Action',
      children: (
        <Flex>
          {role === 'LEVEL0' && (
            <UpgradeToLvl1Modal
              userId={userId || ''}
              username={username || '-'}
              refresh={refetch}
            />
          )}
          {role === 'LEVEL2' && (
            <UpgradeToLDModal
              userId={userId || ''}
              username={username || '-'}
              refresh={refetch}
            />
          )}
          {role !== 'LEVEL0' && (
            <SetRoleModal
              roleId={roleId || ''}
              userId={userId || ''}
              username={username || '-'}
              refresh={refetch}
            />
          )}
          {ldContract && role === 'LD' && (
            <UpdateLdContractModal
              userId={userId || ''}
              username={username || '-'}
              refresh={refetch}
              data={ldContract}
            />
          )}
        </Flex>
      ),
    },
  ];

  return (
    <Card>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions
          bordered
          layout="vertical"
          title="User role info"
          items={roleData}
        />
      )}
    </Card>
  );
};
export default RoleInfo;
