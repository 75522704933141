import { Button, Modal } from 'antd';
import { useBlockUser } from 'hooks';
import React from 'react';

type BlockUserModalProps = {
  userId: string;
  is_suspended: boolean;
  refresh: () => void;
};

const BlockUserModal: React.FC<BlockUserModalProps> = ({
  userId,
  refresh,
  is_suspended,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: blockUser, isLoading: blockLoading } = useBlockUser();

  const onFinish = async () => {
    blockUser({ user_id: userId, is_suspended: !is_suspended }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: 'unset', padding: '0 4px', color: 'red' }}
        loading={blockLoading}
      >
        {is_suspended ? 'Unblock user' : 'Block user'}
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => onFinish()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title={is_suspended ? 'Unblock user' : 'Block user'}
        cancelText="Close"
        okText="Confirm"
        okButtonProps={{ danger: true }}
        destroyOnClose
      >
        {is_suspended
          ? 'You want unblock this user. Are you sure?'
          : 'You want block this user. Are you sure?'}
      </Modal>
    </>
  );
};
export default BlockUserModal;
