import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'statics';
import useLocalStorageState from 'use-local-storage-state';

const usePublic = () => {
  const [token] = useLocalStorageState('magix-admin-token');
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token && location.pathname.includes('auth')) {
      navigate(routes.dashboard.url);
    }
  }, []);
};

export default usePublic;
