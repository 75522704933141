import { Button, ButtonProps } from 'antd';

type TableButtonProps = { title: string } & ButtonProps;

const TableButton: React.FC<TableButtonProps> = ({ title, ...props }) => {
  return (
    <Button
      type="link"
      style={{ minHeight: 'unset', padding: '0 4px' }}
      {...props}
    >
      {title}
    </Button>
  );
};
export default TableButton;
