type generateFormPropsType = (
  rules: Record<string, any>,
  errors: Record<string, any>,
  fields: Record<string, string>
) => Record<string, any>;

const generateFormProps: generateFormPropsType = (rules, errors, fields) =>
  Object.fromEntries(
    Object.entries(fields).map(([key, label]) => [
      key,
      {
        label: label,
        name: key,
        placeholder: label,
        rules: rules[key],
        validateStatus: errors[key] ? 'error' : undefined,
        help: errors[key],
      },
    ])
  );

export default generateFormProps;
