import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Verification, Login as LoginState } from './components';

const Login: React.FC = () => {
  const [loginState, setLoginState] = React.useState<'login' | 'verification'>(
    'login'
  );
  const [requirements, setRequirements] = React.useState<{
    token: string | null;
    username: string | null;
  }>({ token: null, username: null });

  const { Title, Text } = Typography;

  return (
    <Row justify="center" align="middle" style={{ padding: 20 }}>
      <Col
        xs={{ span: 24, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        xl={{ span: 8, offset: 0 }}
      >
        <div style={{ paddingBottom: '40px' }}>
          <Title>
            {loginState === 'login' ? 'Sign In' : 'Sign In Verification'}
          </Title>
          <Text>
            {loginState === 'login'
              ? 'Enter your email and password.'
              : 'A code has been sent to your email.'}
          </Text>
          {loginState === 'verification' && (
            <Text>Enter your email and the code to sign in</Text>
          )}
        </div>
        {loginState === 'login' ? (
          <LoginState
            setLoginState={({ token, username }) => {
              setLoginState('verification');
              setRequirements({ token, username });
            }}
          />
        ) : (
          <Verification
            token={requirements.token}
            username={requirements.username}
          />
        )}
      </Col>
    </Row>
  );
};

export default Login;
