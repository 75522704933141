import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type {
  ErrorResponse,
  UpgradeToLvl1Response,
  UpgradeToLvl1Payload,
} from 'types';

const useUpgradeToLvl1 = (): UseMutationResult<
  UpgradeToLvl1Response,
  ErrorResponse,
  UpgradeToLvl1Payload
> => useMutation((data) => api.post(url.upgradeToLvl1, data));

export default useUpgradeToLvl1;
